export const dataOurTeam =[
{
    id:'1',
    title:'Directors',
team:[
{
    id:'1',
    name:'Mr. P.S. Shekar',
    position:'Managing Director',
    text:'At sps we treat employees with respect and value their contributions. Recognize their achievements, both publicly and privately, and provide constructive feedback to help them grow professionally.'
},


]
},
{
    id:'2',
    title:'Management',
team:[
{
    id:'1',
    name:'Mr. K.R. Premkumar',
    position:'President Operations',
    text:'At sps we  ensure clear and open communication with employees.support employees in their roles by providing the necessary resources, training'
},
{
    id:'2',
    name:'Mr. R. Satheesh',
    position:'Head Marketing',
    text:'At sps we plan, execute, and evaluate marketing campaigns to reach target audiences and achieve marketing objectives. '
},
{
    id:'3',
    name:'Mr.K. Divakar',
    position:'Head Designs',
    text:'At sps we plan, execute, and evaluate marketing campaigns to reach target audiences and achieve marketing objectives. '
},
{
    id:'4',
    name:'Mr.M. Ravi',
    position:'Head Procurement',
    text:'At sps we plan, execute, and evaluate marketing campaigns to reach target audiences and achieve marketing objectives. '
},
{
    id:'5',
    name:'Mr.E. Premkumar',
    position:'Works Incharge',
    text:'At sps we plan, execute, and evaluate marketing campaigns to reach target audiences and achieve marketing objectives. '
},
{
    id:'6',
    name:'Mr.C.S. Sudarsan',
    position:'Head Finance',
    text:'At sps we plan, execute, and evaluate marketing campaigns to reach target audiences and achieve marketing objectives. '
},

{ 
    id:'7',
    name:'Mr.D. Sridhar',
    position:'Dy.Manager - HR',
    text:'At sps we plan, execute, and evaluate marketing campaigns to reach target audiences and achieve marketing objectives. '
},
]
}
]