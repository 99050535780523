import React from 'react';
import {Box, Typography} from '@mui/material';
import {Card, CardContent, CardMedia} from '@mui/material';
import { dataSupportTeams } from '../../data/Home';
import { Container, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import arrow from '../../assets/src/icon/arrow.png';
import Slider from "react-slick";

const TeamImgBox = styled(CardMedia)(({theme}) => ({
  borderRadius: '50%',
  width: '150px',
  height: '150px',
  margin:'auto'
}));

const SupportTeam = () => {
  const slickTeams = {
    dots: true,
    infinite: false,
    autoplay: false,
    arrows: false,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  }; 
  return (
    <React.Fragment>
    <Box sx={{py:{xs:3,md:6,lg:8}}}>
    <Container>
    <Typography variant='h2' textAlign='center'>Presenting our support team</Typography>
    <Box sx={{py:3}}>
      <Slider {...slickTeams}>
        {dataSupportTeams.map((team)=>
            <>
        <Card sx={{margin:1}} key={team.id}>
      <CardContent style={{padding:'32px'}}>
        <Typography variant="h5" className='fw-500'>
         {team.name}
        </Typography>
        <Stack direction="row" useFlexGap spacing={1} alignItems="center" mb={1}>
        <img src={arrow} alt="arrow-img" style={{width:'10px',height:'10px'}} />
        <Typography variant="body2" sx={{fontWeight:600}}>
        Contact
        </Typography>
      </Stack>
        <Typography variant="body2" color="text.main">
          Mobile : {team.number}
        </Typography>
      </CardContent>
         </Card>
         </>
        )}
        </Slider>
        
    </Box>
    </Container>
    </Box>
    </React.Fragment>
  )
}

export default SupportTeam